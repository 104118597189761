import {BaseObject} from './core/BaseObject';
import Collection from './core/Collection';
import Model from './core/Model';
import {apiPostAny, getToken} from './core/ApiCalls';
import Axios from 'axios';

interface _File extends BaseObject {
  fileName: string;
  mimeType: string;
  name: string;
  organisationId: string;
  organisationName?: string;
  originalName: string;
  size?: number;
  type: string;
  url: string;
}

export class File extends Model<_File, FileCollectionClass> {
  public collection = FileCollection;

  get fileName(): string {
    return this.data.fileName;
  }

  set fileName(value: string) {
    this.data.fileName = value;
  }

  get mimeType(): string {
    return this.data.mimeType;
  }

  set mimeType(value: string) {
    this.data.mimeType = value;
  }

  get originalName(): string {
    return this.data.originalName;
  }

  set originalName(value: string) {
    this.data.originalName = value;
  }

  get organisationId() {
    return this.data.organisationId;
  }

  get organisation() {
    return this.data.organisationName
  }

  get size(): number {
    return this.data.size;
  }

  set size(value: number) {
    this.data.size = value;
  }

  get type(): string {
    return this.data.type;
  }

  set type(value: string) {
    this.data.type = value;
  }
}

export class FileCollectionClass extends Collection<_File, File> {
  cache = {};
  privateFinds = true;
  public route = '/files';

  async upload(file: any, organisationId: string) {
    return apiPostAny(this.url + this.route, file, true, {organisationId});
  }

  async fetch(fileName: string, refreshCache = false) {
    const cacheKey = 'fetchFile' + fileName;
    // console.log('Fetching file with ', cacheKey)

    if (this.cache[cacheKey] && !refreshCache) {
      return this.cache[cacheKey];
    }

    const action = Axios.get(this.url + this.route + '/' + fileName, {
      headers: {
        authorization: getToken()
      },
      responseType: 'arraybuffer'
    }).then(res => {
      return `data:${res.headers['content-type']};base64,${new Buffer(res.data).toString('base64')}`;
    })

    this.cache[cacheKey] = action;

    return action;

  }

  async fetchThumb(fileName: string, format = 'png', refreshCache = false) {
    const cacheKey = 'fetchThumb' + fileName + format;
    // console.log('Fetching file with ', cacheKey)

    if (this.cache[cacheKey] && !refreshCache) {
      return this.cache[cacheKey];
    }

    const action = Axios.get(this.url + this.route + '/thumb/' + fileName + `?format=${format}`, {
      headers: {
        authorization: getToken()
      },
      responseType: 'arraybuffer'
    }).then(res => {
      return `data:${res.headers['content-type']};base64,${new Buffer(res.data).toString('base64')}`;
    })

    this.cache[cacheKey] = action;

    return action;

  }

}

export const FileCollection = new FileCollectionClass(File);

