import React, {Context, createContext, useLayoutEffect, useRef, useState} from 'react';
import {User, UserCollection} from '../models/User';
import {Module, modules} from '../constants/Modules';
import {Roles} from '../constants/Roles';
import {useHistory} from 'react-router-dom';
import {ConfirmationModalProps} from '../components/modals/ConfirmationModal';
import {PreviewBackdropProps} from '../components/modals/PreviewBackdrop';
import Axios from 'axios';
import {NotificationProps} from '../components/modals/Notification';
import {useSnackbar, withSnackbar} from 'notistack';
import {AlertSeverity} from '../components/snippets/Alert';

export const AppContext: Context<AppContextValues> = createContext(null);

export const AppProvider = (props: AppContextProps) => {
  const me = useRef({
    user: null as User,
    triedToAccessUrl: null
  })
  const getMe = () => me.current;

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const history = useHistory();

  const [user, setUser] = useState(null);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState({});

  const [previewBackdropOpen, setPreviewBackdropOpen] = useState(false);
  const [previewBackdropProps, setPreviewBackdropProps] = useState({});

  const showConfirmationModal = (props: ConfirmationModalProps) => {
    setConfirmationModalProps(props);
    setConfirmationModalOpen(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const showPreviewBackdrop = (props: PreviewBackdropProps) => {
    setPreviewBackdropProps(props);
    setPreviewBackdropOpen(true);
  };

  const hidePreviewBackdrop = () => {
    setPreviewBackdropOpen(false);
  };

  const hasPermission = (module: Module) => {
    const me = getMe();
    const user = me.user;

    if (!user) {
      return false;
    }
    if (!module) {
      return false;
    }

    if (user.level === Roles.Admin) {
      return true;
    }

    if (module.permissions.includes(user.level)) {
      return true;
    }
    return false;
  }

  const getSidebarModules = () => {
    return modules.filter(m => m.navbar !== false && hasPermission(m));
  }

  const isAuthorized = (module: Module) => {
    const me = getMe();
    const user = me.user;

    let authorized = false;
    for (let i = 0; i < module.permissions.length; i++) {
      if (user.level <= module.permissions[i]) {
        authorized = true;
      }
    }
    return authorized;
  }

  const getFirstPermittedModule = () => {
    let firstModule;
    for (const m of modules) {
      if (isAuthorized(m) && !firstModule) {
        firstModule = m;
      }
    }
    return firstModule;
  }

  const login = async (email: string, password: string): Promise<User> => {
    return UserCollection.login(email, password).then(user => {
      const me = getMe();
      me.user = user;
      setUser(me.user);
      return user;
    });
  }

  const logout = (reason = '') => {
    if (!window.location.href.includes('/login')) {
      const me = getMe();
      me.user = null;
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
  };

  const showNotification = (message: string, type?: AlertSeverity) => {
    enqueueSnackbar(message, {
      variant: type || 'info',
      autoHideDuration: 3000,
    });
  }

  useLayoutEffect(() => {
    Axios.interceptors.response.use((res) => res, (error) => {
      if (error?.response?.status === 401) {
        // logout('Je sessie is verlopen.');
      }

      return Promise.reject(error);
    })
  }, []);

  const values: AppContextValues = {
    user,
    isAuthorized,
    login,
    logout,
    hasPermission,
    getFirstPermittedModule,
    getSidebarModules,

    /** Confirmation modal */
    showConfirmationModal,
    confirmationModalOpen: confirmationModalOpen,
    confirmationModalProps: confirmationModalProps,
    hideConfirmationModal: hideConfirmationModal,

    /** Preview backdrop */
    showPreviewBackdrop,
    previewBackdropOpen: previewBackdropOpen,
    previewBackdropProps: previewBackdropProps,
    hidePreviewBackdrop: hidePreviewBackdrop,

    /** Notifications */
    showNotification
  }

  useLayoutEffect(() => {
    const me = getMe();
    const nonAuthRoutes = ['/login', '/accept-invitation', '/password-reset'];

    if (!me.user && nonAuthRoutes.every(route => !window.location.href.includes(route))) {
      UserCollection.findMe().then(user => {
        me.user = user;
        setUser(me.user);
      }).catch(() => {
        localStorage.setItem('prevUrl', window.location.href);
        console.log('-------CATCH USER FINDME ----')
        // TODO: Uncomment this
        // logout('Session timed out')
      })
    }
  }, []);

  return (
    <AppContext.Provider value={values}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;

interface AppContextProps {
  children?: any;
}

interface AppContextValues {
  user: User;
  isAuthorized: (module: Module) => boolean;
  login: (username: string, password: string) => Promise<User>;
  logout: () => void;
  hasPermission: (module: Module) => boolean;
  getFirstPermittedModule: () => Module;
  getSidebarModules: () => Module[];

  /** Confirmation modal */
  showConfirmationModal: (props: ConfirmationModalProps) => void;
  hideConfirmationModal: () => void;
  confirmationModalOpen: boolean;
  confirmationModalProps: any;

  /** Preview backdrop */
  showPreviewBackdrop: (props: PreviewBackdropProps) => void;
  hidePreviewBackdrop: () => void;
  previewBackdropOpen: boolean;
  previewBackdropProps: any;

  showNotification: (message: string, type?: AlertSeverity) => void;
}
