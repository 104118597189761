import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {fabric} from 'fabric-with-gestures';
import {FileCollection} from '../../models/File';
import {theme} from '../../assets/themes/main-theme';
import {isMobileDevice, newObjectId} from '../../utils';
import Loader from './Loader';
import {Box, Fade, Typography} from '@material-ui/core';
import KeyBinding from '../snippets/KeyBinding';
import Zoom from '../snippets/Zoom';

const useStyles = makeStyles(theme => ({
  root: (props: CanvasPanelProps) => ({
    width: '100%',
    height: props.height ?? '100%',
    position: 'relative'
  }),
  fileInput: {
    position: 'fixed',
    height: 0,
    width: 0,
    opacity: 0,
    top: -1000
  },
  overlay: (props: CanvasPanelProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 30
  }),
  keybindings: {
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#ECF0F1',
    padding: theme.spacing(2, 2, 1, 2),
    borderRadius: 8,
    top: 16,
    right: 16
  },
  zoom: {
    position: 'absolute',
    borderRadius: 4,
    bottom: 16,
    right: 16
  }
}));

const minZoom = 0.75;
const maxZoom = 5;

export const CanvasPanel = forwardRef((props: CanvasPanelProps, ref: any) => {
    const classes = useStyles(props);
    const canvasId = 'canvas';
    const isMobile = isMobileDevice();

    const [loading, setLoading] = useState(true);
    const [isDragging, setIsDragging] = useState(false);

    const getMe = () => me.current;
    const me = useRef({
      active: false,
      backgroundImage: null,
      canvas: null,
      viewportWidth: null,
      viewportHeight: null,
      items: [],
      ids: {} as { [prefix: string]: Set<number> },
      objectOptions: {} as any,
      counter: {}
    });

    const canvasRef = useRef(null);

    const objectModifiersDependency = JSON.stringify(props.objectModifiers);

    const clearSettings = () => {
      const me = getMe();

      if (!me.canvas) {
        return
      }

      const canvas = me.canvas;

      me.counter = {};
      me.items = [];
      me.objectOptions = {}

      canvas.clear();
      canvas.dispose();
    }

    const keyDownHandler = useCallback((event) => {
      if (event.key === 'd' && event.ctrlKey === true) {
        duplicateSelection();
      }
      if (event.key === 'Backspace' && event.ctrlKey === true) {
        handleRemoveSelection();
      }
    }, [])

    useEffect(() => {
      if (props.editMode) {
        document.addEventListener('keydown', keyDownHandler);
      }

      return () => {
        document.removeEventListener('keydown', keyDownHandler);

        clearSettings();
      }
    }, [])

    useEffect(() => {
      const initializer = async () => {
        if (props.active) {
          setLoading(true);
          await initCanvas(addZoom, addMove, addClickListener, addSnap);

          await (props.json ? importCanvas(props.json) : initBackground());

          if (props.onLoad) {
            props.onLoad(getCanvasObjects());
          }

          setLoading(false);
        } else {
          clearSettings();
        }
      }

      initializer();

    }, [props.active]);

    useEffect(() => {
      const me = getMe();

      if (props.objectModifiers && me.canvas) {
        me.canvas.forEachObject(obj => {
          const modifiers = props.objectModifiers[obj.id];

          obj.backgroundColor = undefined;
          obj.opacity = 1;

          if (modifiers?.backgroundColor) {
            obj.backgroundColor = modifiers.backgroundColor;
          }

          if (modifiers?.opacity) {
            obj.opacity = modifiers.opacity;
          }
        })

        me.canvas.renderAll();
      }
    }, [objectModifiersDependency]);

    useImperativeHandle(ref, () => ({
      addImage(imageId: string, title: string, typeId: string): void {
        addObject(imageId, title, typeId);
      },
      removeSelectedObject(): void {
        handleRemoveSelection();
      },
      exportCanvas(): Record<string, any> {
        return exportCanvas();
      },
      undo(): void {
        const me = getMe();
        me.canvas.undo();
      },
      duplicate(): void {
        duplicateSelection();
      },
      updateSelectedObject(values: UpdateObjectFields): void {
        updateSelectedObject(values);
      }
    }));

    interface UpdateObjectFields {
      name?: string;
      id?: number;
      number?: string;
      prefix?: string;
      flex?: boolean;
    }

    const getCanvasObjects = () => {
      const me = getMe();

      if (!me.canvas) {
        return null;
      }

      const keys = me.canvas.extraProps;
      const objects = [];

      for (const obj of me.canvas.getObjects()) {
        const newObj = {};
        for (const key of keys) {
          newObj[key] = obj[key];
        }
        objects.push(newObj);
      }

      return objects;
    }

    const updateSelectedObject = (values: UpdateObjectFields): void => {
      const me = getMe();
      const activeObjects = me.canvas.getActiveObjects();

      if (activeObjects.length === 0) {
        return;
      }

      const object = activeObjects[0];
      const oldObject = {...object};

      object.set(values)

      if (values.number || values.prefix !== undefined) {
        /** Force IDs update */
        getNextItemNumber(object.prefix, object.number, oldObject);

        const text = object.getObjects()?.find(obj => obj.type === 'text');
        const objDisplayName = object.prefix ? `${object.prefix}${object.number}` : `${object.number}`;

        if (text) {
          text.set({text: objDisplayName} as any)
        }
      }

      me.canvas.renderAll();

    }

    const setElementSettings = (element, options: AddObjectOptions = {}) => {
      if (!props.editMode) {
        element.lockMovementX = true;
        element.lockMovementY = true;
        element.hasControls = false;
      }

      element.cornerStyle = 'circle';
      element.cornerSize = 10;
      element.cornerColor = theme.palette.primary.main;
      element.strokeWidth = 4;
      element.borderColor = theme.palette.primary.main;
      element.cornerStrokeColor = theme.palette.primary.main;

      if (!options.skewable) {
        element.setControlsVisibility({
          ml: false,
          mr: false,
          mb: false,
          mt: false,
          mtr: false,
        })
      }
    }

    const importCanvas = async (canvasJson: any) => {
      const me = getMe();
      const fabric = {...canvasJson};

      const promises = [];

      me.backgroundImage = fabric.backgroundImage;

      /** Load background from fabric.backgroundImage */
      promises.push(FileCollection.fetch(fabric.backgroundImage.fileId).then(res => {
        fabric.backgroundImage.src = res;
      }));

      if (!props.editMode) {
        fabric.objects = fabric.objects.filter(obj => obj.flex);
      }

      /** Load object images from fabric.objects[i].fileId */
      for (const group of fabric.objects) {
        for (const obj of group.objects) {
          if (obj.type === 'image') {
            promises.push(FileCollection.fetch(group.fileId).then(res => {
              obj.src = res;
            }));
          }
        }
      }

      return Promise.all(promises).then(() => {
        me.canvas.clear();

        return new Promise<void>((resolve, reject) => {
          me.canvas.loadFromJSON(fabric, () => {
            // console.log('Success!')
            me.canvas.forEachObject(obj => {
              setElementSettings(obj);
              getNextItemNumber(obj.prefix, obj.number);
            });
            resolve();
          });
        })

      })
    }

    const exportCanvas = () => {
      const me = getMe();

      if (!me) {
        return null;
      }

      const canvasJson = me.canvas.toJSON(me.canvas.extraProps)

      delete canvasJson.backgroundImage.src;

      /** First layer of objects are groups */
      for (const obj of canvasJson.objects) {
        if (obj.objects) {
          for (const _obj of obj.objects) {
            delete _obj.src;
          }
        }
      }

      return canvasJson;
    }

    const handleRemoveSelection = () => {
      const me = getMe();

      for (const obj of me.canvas.getActiveObjects()) {
        me.canvas.remove(obj);
        removeItemNumber(obj)
      }
      me.canvas.discardActiveObject();
      me.canvas.renderAll();
    }

    const initCanvas = (...features) => {
      const me = getMe();

      me.viewportWidth = props.rootRef.current.offsetWidth
      me.viewportHeight = props.rootRef.current.offsetHeight

      me.canvas = new fabric.Canvas(canvasId, {
        width: me.viewportWidth,
        height: me.viewportHeight,
        selectionColor: '#EEEEEE75',
        selectionBorderColor: '#c3c3c3',
        // centeredScaling: true,
        allowTouchScrolling: isMobile,
        selection: !isMobile
      });


      me.canvas.extraProps = ['id', 'number', 'notes', 'fileId', 'name', 'typeId', 'flex', 'prefix'];

      me.canvas.historyInit();
      me.canvas.clear();

      if (features) {
        for (const f of features) f();
      }
    }

    const initBackground = async () => {
      const me = getMe();
      const fileId = props.backgroundId;

      const bg = await getFabricImage(fileId);
      bg['fileId'] = fileId;
      const ratio = me.canvas.width / bg.width

      me.backgroundImage = me.canvas.setBackgroundImage(bg, me.canvas.renderAll.bind(me.canvas), {
        scaleX: ratio,
        scaleY: ratio,
        top: (me.canvas.height - (bg.height * ratio)) / 2
      })

      me.canvas.renderAll();
    }

    const addClickListener = () => {
      const me = getMe();
      const canvas = me.canvas;

      const invoke = (target) => {
        handleSelectionChange(target);
      }

      canvas.on('selection:created', (event) => {
        invoke(event.selected);
      })

      canvas.on('selection:updated', (event) => {
        invoke(event.selected);
      })

      canvas.on('selection:cleared', (event) => {
        invoke(event.selected);
      })

      canvas.on('mouse:up', (event) => {
        if (event.target) {
          invoke([event.target]);
        }
      })

    }

    const getCenter = () => {
      const me = getMe();
      const canvas = me.canvas;

      const circle = new fabric.Circle({
        radius: 0
      });

      canvas.add(circle);
      canvas.centerObject(circle);

      const pos = {
        x: circle.viewportCenter().left,
        y: circle.viewportCenter().top
      }

      canvas.remove(circle);

      return pos
    }

    const handleZoomIn = () => {
      const me = getMe();
      const canvas = me.canvas;
      const center = getCenter();
      let zoom = canvas.getZoom();

      zoom += 0.5;

      if (zoom > maxZoom) {
        zoom = maxZoom;
      }

      if (zoom < minZoom) {
        zoom = minZoom;
      }

      canvas.zoomToPoint(center, zoom);
    }

    const handleZoomOut = () => {
      const me = getMe();
      const canvas = me.canvas;
      const center = getCenter();
      let zoom = canvas.getZoom();

      zoom -= 0.5;

      if (zoom > maxZoom) {
        zoom = maxZoom;
      }

      if (zoom < minZoom) {
        zoom = minZoom;
      }
      canvas.zoomToPoint(center, zoom);
    }

    const addZoom = () => {
      const me = getMe();
      const canvas = me.canvas;

      canvas.on('mouse:wheel', (opt) => {
        const delta = opt.e.deltaY;
        let zoom = canvas.getZoom();

        zoom *= 0.999 ** delta;

        if (zoom > maxZoom) {
          zoom = maxZoom;
        }

        if (zoom < minZoom) {
          zoom = minZoom;
        }
        canvas.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom);

        opt.e.preventDefault();
        opt.e.stopPropagation();
      })
    }

    const addSnap = () => {
      // const me = getMe();
      // const canvas = me.canvas;
      // const [canvasWidth, canvasHeight] = [canvas.width, canvas.height];
      // let activeObject = canvas.getActiveObject();
      // const edgeDetectionPixels = 10;
      //
      // canvas.on('object:moving', function (e) {
      //   var obj = e.target;
      //   obj.setCoords(); //Sets corner position coordinates based on current angle, width and height
      //
      //   if(obj.left < edgeDetectionPixels) {
      //     obj.left = 0;
      //   }
      //
      //   if(obj.top < edgeDetectionPixels) {
      //     obj.top = 0;
      //   }
      //
      //   if((obj.width + obj.left) > (canvasWidth - edgeDetectionPixels)) {
      //     obj.left = canvasWidth - obj.width;
      //   }
      //
      //   if((obj.height + obj.top) > (canvasHeight - edgeDetectionPixels)) {
      //     obj.top = canvasHeight - obj.height;
      //   }
      //
      //   canvas.forEachObject(function (targ) {
      //     activeObject = canvas.getActiveObject();
      //
      //     if (targ === activeObject) return;
      //
      //
      //     if (Math.abs(activeObject.oCoords.tr.x - targ.oCoords.tl.x) < edgeDetectionPixels) {
      //       activeObject.left = targ.left - activeObject.currentWidth;
      //     }
      //     if (Math.abs(activeObject.oCoords.tl.x - targ.oCoords.tr.x) < edgeDetectionPixels) {
      //       activeObject.left = targ.left + targ.currentWidth;
      //     }
      //     if (Math.abs(activeObject.oCoords.br.y - targ.oCoords.tr.y) < edgeDetectionPixels) {
      //       activeObject.top = targ.top - activeObject.currentHeight;
      //     }
      //     if (Math.abs(targ.oCoords.br.y - activeObject.oCoords.tr.y) < edgeDetectionPixels) {
      //       activeObject.top = targ.top + targ.currentHeight;
      //     }
      //     if (activeObject.intersectsWithObject(targ) && targ.intersectsWithObject(activeObject)) {
      //       targ.strokeWidth = 10;
      //       targ.stroke = 'red';
      //     } else {
      //       targ.strokeWidth = 0;
      //       targ.stroke = false;
      //     }
      //     if (!activeObject.intersectsWithObject(targ)) {
      //       activeObject.strokeWidth = 0;
      //       activeObject.stroke = false;
      //     }
      //   });
      // });
    }

    const addMove = () => {
      const me = getMe();
      const canvas = me.canvas;
      const [halfWidth, halfHeight] = [canvas.getWidth() / 2, canvas.getHeight() / 2];
      const [viewPortWidth, viewPortHeight] = [canvas.getWidth(), canvas.getHeight()];
      let [imgWidth, imgHeight] = [0, 0];
      let clientX, clientY;

      canvas.on({
        'mouse:down': (opt) => {
          // console.log('mouse:down')
          const e = opt.e;
          if (e.altKey === true || isMobile) {
            setIsDragging(true);
            canvas.isDragging = true;
            canvas.selection = false;
            clientX = isMobile ? e.touches[0].clientX : e.clientX;
            clientY = isMobile ? e.touches[0].clientY : e.clientY;
            canvas.lastPosX = clientX;
            canvas.lastPosY = clientY;
          }
        },
        'mouse:move': (opt) => {
          // console.log(me.backgroundImage);
          imgWidth = me.backgroundImage.width * me.backgroundImage.scaleX;
          imgHeight = me.backgroundImage.height * me.backgroundImage.scaleY;
          // console.log('mouse:move', canvas.isDragging)
          if (canvas.isDragging) {
            const e = opt.e;
            const vpt = canvas.viewportTransform;
            const zoom = canvas.getZoom();
            clientX = isMobile ? e.touches[0].clientX : e.clientX;
            clientY = isMobile ? e.touches[0].clientY : e.clientY;
            vpt[4] += clientX - canvas.lastPosX;
            vpt[5] += clientY - canvas.lastPosY;
            canvas.requestRenderAll();
            canvas.lastPosX = clientX;
            canvas.lastPosY = clientY;

            // vpt[4] = X axes
            if (vpt[4] >= halfWidth) {
              vpt[4] = halfWidth;
            } else if (vpt[4] < (-imgWidth * zoom) + halfWidth) {
              vpt[4] = (-imgWidth * zoom) + halfWidth
            }

            // (me.canvas.height - (bg.height * ratio)) / 2

            // vpt[5] = Y axes
            if (vpt[5] >= (imgHeight / 2 - halfHeight) * zoom) {
              vpt[5] = (imgHeight / 2 - halfHeight) * zoom;
            } else if (vpt[5] < (-(imgHeight / 2) - halfHeight) * zoom) {
              vpt[5] = (-(imgHeight / 2) - halfHeight) * zoom;
            }
          }
        },
        'mouse:up': function (opt) {
          // console.log('mouse:up')
          // on mouse up we want to recalculate new interaction
          // for all objects, so we call setViewportTransform
          canvas.setViewportTransform(canvas.viewportTransform);
          canvas.isDragging = false;
          setIsDragging(false);

          if (props.editMode) {
            canvas.selection = true;
          }
          canvas.defaultCursor = 'pointer';
        },
        'mouse:hover': function (opt) {
          const evt = opt.e;
          if (evt.altKey === true) {
            canvas.hoverCursor = 'grab';
          }
        }
      })
    }

    const handleSelectionChange = (selection = []) => {
      const me = getMe();

      const object = selection[0]?.getObjects()?.find(o => o.type === 'image');

      if (!object) {
        return;
      }

      if (!props.editMode) {
        me.canvas.setActiveObject(selection[0]);
      }

      me.objectOptions.width = object.width;
      me.objectOptions.height = object.height;
      me.objectOptions.scaleX = object.scaleX;
      me.objectOptions.scaleY = object.scaleY;
      me.objectOptions.ratio = object.scaleX;

      if (props.onSelectionChange) {
        props.onSelectionChange(selection);
      }
    }

    const removeItemNumber = (object) => {
      const me = getMe();
      const prefixKey = object.prefix || 'default';
      if (me.ids[prefixKey]) {
        me.ids[prefixKey].delete(object.number);
      }
    }

    const updateIds = (prefix, id) => {
      const me = getMe();
      const prefixKey = prefix || 'default';
      if (me.ids[prefixKey]) {

      }
    }

    const getNextItemNumber = (prefix = '', number?: number, originalObject?: any) => {
      const me = getMe();

      const prefixKey = prefix || 'default';
      let nextAvailable = 1;

      /** If we haven't started counting yet for given prefix. */
      if (!me.ids[prefixKey]) {
        me.ids[prefixKey] = new Set([]);
      }

      if (originalObject !== undefined) {
        me.ids[originalObject.prefix || 'default']?.delete(originalObject.number);
      }

      if (number === undefined) {
        /** If we actually want the next available number */

        nextAvailable = me.ids[prefixKey].size ? Math.max(...me.ids[prefixKey]) + 1 : 1;
        me.ids[prefixKey].add(nextAvailable)
      } else {
        /** If we have supplied the ID and just want to register it */

        nextAvailable = number;
        me.ids[prefixKey].add(number);
      }

      return nextAvailable;
    }

    const duplicateSelection = async () => {
      const me = getMe();
      const activeObjects = me.canvas.getActiveObjects();
      const groups = [];

      if (!activeObjects || activeObjects.length === 0) {
        return;
      }

      const cloneObject = async (obj) => {
        const number = getNextItemNumber(obj.prefix || '');

        const group: any = await new Promise((resolve, reject) => {
          obj.clone(cb => {
            resolve(cb);
          });
        })

        const textObject = group.getObjects()?.find(obj => obj.type === 'text');

        group.left = obj.left + 5;
        group.top = obj.top + 5;

        /** Custom attributes */
        group['id'] = newObjectId();
        group['number'] = number;
        group['name'] = obj.name
        group['typeId'] = obj.typeId;
        group['fileId'] = obj.fileId;
        group['flex'] = obj.flex;
        group['notes'] = obj.notes;
        group['prefix'] = obj.prefix;

        const objDisplayName = group.prefix ? `${group.prefix}${group.number}` : `${group.number}`;

        textObject.set({text: objDisplayName} as any);

        return group;
      }

      for (const obj of activeObjects) {
        groups.push(await cloneObject(obj));
      }

      const selection = addObjectsToCanvas(groups);
      selection.left = activeObjects[0].left + 5;
      selection.top = activeObjects[0].top + 5;
      me.canvas.renderAll();
    }

    const addObjectsToCanvas = (objects: any[], options: AddObjectOptions = {}): fabric.ActiveSelection => {
      const me = getMe();
      const canvas = me.canvas;
      const zoom = canvas.getZoom();
      const vpt = canvas.viewportTransform;

      for (const obj of objects) {
        if (options.center) {
          /** Center file in viewport */
          const ratio = obj.scaleX;
          const [halfWidth, halfHeight] = [canvas.getWidth() / 2, canvas.getHeight() / 2];
          const [centerX, centerY] = [(-vpt[4] + halfWidth) / zoom - (obj.width * ratio / 2), (-vpt[5] + halfHeight) / zoom - (obj.height * ratio / 2)];

          obj.left = centerX;
          obj.top = centerY;
        }

        setElementSettings(obj, options)

        obj.on('event:modified', (opt) => {
          handleSelectionChange([{...obj}])
        })

        canvas.add(obj)
      }

      const selection = new fabric.ActiveSelection(objects, {canvas});
      setElementSettings(selection, options);
      canvas.setActiveObject(selection);
      canvas.renderAll();

      handleSelectionChange(objects);

      return selection;
    }

    const addObject = async (imageId: string, title: string, typeId: string): Promise<void> => {
      const me = getMe();
      const img = await getFabricImage(imageId)
      const number = getNextItemNumber('');
      const ratio = 80 / img.width
      const [scaleX, scaleY] = [me.objectOptions.scaleX || ratio, me.objectOptions.scaleY || ratio];

      /** Scale image */
      img.scale(1).set({
        scaleX: scaleX,
        scaleY: scaleY,
        lockRotation: true,
        backgroundColor: '#EEEEEEA0',
      });

      const text = new fabric.Text(`${number}`, {
        fontSize: 28,
        fontFamily: 'Lato',
        textBackgroundColor: theme.palette.primary.main,
      });

      // text.set('top', (img.getBoundingRect().height * scaleY / 2) - (text.height * scaleY / 2));
      // text.set('left', (img.getBoundingRect().width * scaleX / 2) - (text.width * scaleX / 2));
      // text.set('left', (img.getBoundingRect().width * scaleX) - (text.getBoundingRect().width * scaleX));

      const group = new fabric.Group([img, text]);

      /** Custom attributes */
      group['id'] = newObjectId();
      group['number'] = number;
      group['name'] = title;
      group['typeId'] = typeId;
      group['fileId'] = imageId;
      // group['prefix'] = prefix;

      addObjectsToCanvas([group], {center: true});
    }

    const getFabricImage = async (fileName: string): Promise<fabric.Image> => {
      const file = await FileCollection.fetch(fileName);
      const image = new Image();
      image.src = file;

      const imageOnloadPromise = new Promise((resolve, reject) => {
        image.addEventListener('load', (event) => {
          resolve(event);
        });
      })

      await imageOnloadPromise;

      return new fabric.Image(image);
    }

    return (
      <div className={classes.root}>
        <canvas id={canvasId} ref={canvasRef}/>

        <Fade in={!isDragging}>
          <Box boxShadow={8} className={classes.keybindings}>
            <Typography variant={'subtitle2'} style={{lineHeight: 1.4}}>Bewegen</Typography>
            {isMobile ? (
              <KeyBinding modifiers={[]} keys={['drag']} component={'tag'}/>
            ) : (
              <KeyBinding modifiers={['alt']} keys={['mouse']} component={'tag'}/>
            )}
          </Box>
        </Fade>

        <Box className={classes.zoom} boxShadow={4}>
          <Zoom onZoomIn={handleZoomIn} onZoomOut={handleZoomOut}/>
        </Box>

        <Loader loading={loading}/>
      </div>
    );
  }
);
CanvasPanel.displayName = 'CanvasPanel';

export default CanvasPanel;

interface CanvasPanelProps {
  height?: number;
  active?: boolean;
  rootRef: any;
  json?: any;
  backgroundId?: string;
  onSelectionChange?: (selection: CanvasObject[]) => void;
  onLoad?: (objects: CanvasObject[]) => void;
  editMode?: boolean;
  objectModifiers?: { [objectId: string]: ObjectModifier };
}

export interface CanvasObject {
  id: string;
  number: number;
  notes?: string;
  fileId: string;
  name: string;
  typeId: string;
  flex?: boolean;
  prefix?: string;
}

export interface ObjectModifier {
  backgroundColor?: string;
  opacity?: number;
}

export interface AddObjectOptions {
  center?: boolean;
  skewable?: boolean;
  lockMovement?: boolean;
}

// @ts-ignore
fabric.Canvas.prototype.historyInit = function () {
  this.historyUndo = [];
  this.historyNextState = this.historyNext();

  this.on({
    'object:added': this.historySaveAction,
    'object:removed': this.historySaveAction,
    'object:modified': this.historySaveAction
  })
}

// @ts-ignore
fabric.Canvas.prototype.historyNext = function () {
  return JSON.stringify(this.toDatalessJSON(this.extraProps));
}

// @ts-ignore
fabric.Canvas.prototype.historySaveAction = function () {
  if (this.historyProcessing)
    return;

  const json = this.historyNextState;
  this.historyUndo.push(json);
  this.historyNextState = this.historyNext();
}
// @ts-ignore
fabric.Canvas.prototype.undo = function () {
  // The undo process will render the new states of the objects
  // Therefore, object:added and object:modified events will triggered again
  // To ignore those events, we are setting a flag.
  this.historyProcessing = true;

  const history = this.historyUndo.pop();
  if (history) {
    this.loadFromJSON(history).renderAll();
  }

  this.historyProcessing = false;
}
