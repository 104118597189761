const env = {
  deployment: 'production',
  api: {
    randinctrl: {
      // host: 'https://api.randinctrl.nl'
      host: 'https://h2907698.stratoserver.net'
    },
  },
};
export default env;
